.foot {
	min-height: 40px;
	font-size: 75%;
	font-weight: 100;
	margin-right: 15px;
	margin-top: 10px;
	display: flex;
	justify-content: flex-end;

}
.contact {
	display: flex;
	justify-content: flex-end;
}
.foot div:first-child {
	margin-right: auto;
	margin-left: 15px;
}

.copyright {
	padding: 5px;
}
.contact a {
	border: none;
	color: #E24ADF;
	background: none;
	text-transform: uppercase;
	display: block;
	text-decoration: none;
	padding: 5px;
}