.speaking {
	display: flex;
	flex-wrap: wrap;
}

.speaking section {
	width: 30%;
	margin-bottom: 5%;
	/*padding-right: 10px;*/
	margin-right: 2%;
}

.speaking time {
	color: #4A90E2;
	font-size: 80%;
}

.speaking h3 {
	/*background-color: #4A90E2;*/
	padding: 5px;
	font-size: 200%;
	color: #4A90E2;
	line-height: 120%;
	/* opacity: 0.5; */
}

.speaking h4 {
	display: block;
	margin: 0px;
}

.speaking-item {
	font-size: 75%;
	margin-bottom: 4%;
}
